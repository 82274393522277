.sd-completedpage h3 {
  font-size: 36px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.167;
}

/*Keep this here*/
.sd-navigation__complete-btn {
  background-color: white;
  color: #027ac5;
  border: 2px solid #027ac5;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.sd-body__navigation {
  justify-content: center;
}

.sd-question {
  text-align: center;
}

.sv-string-viewer {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
}

.sd-scrollable-container:not(.sd-scrollable-container--compact) {
  width: max-content;
  overflow: visible;
  max-width: 100%;
  margin: 0 auto;
}
